// Import Styles
@import 'theme/vendors/reset';
@import 'theme/components/buttons';
@import 'theme/mixins/mixins';

// theme settings
.light {
  --background: #ffffff;
}
.dark {
  --background: #0d1117;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

// Removing scrollbars
::-webkit-scrollbar {
  width: 0;  /* remove scrollbar space */
  background: transparent;  /* optional: just make scrollbar invisible */
}

// Base page styling
.page {
  width: 100vw;
  height: 100vh;
  background: var(--background);

  padding: 0;
  margin: 0;
}

// Context menu
.user-menu {
  width: auto;
  height: auto;
  min-width: 172px;
  padding: 3px 10px 3px 10px;

  margin: 0;

  background-color: var(--panel-background);
  box-shadow: 0 0 12pt var(--panel-border);

  border-radius: 2px;
  opacity: 1.0;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  animation: all .3s ease-in;

  h1 {
    font-size: 1rem;
    color: var(--panel-text);
    line-height: 1.5;
    letter-spacing: -0.05px;
    margin-bottom: 5px;
  }

  .break {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--panel-border);
    margin-bottom: 10px;
  }

  .action {
    width: 100%;
    height: auto;
    padding: 5px;

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    cursor: pointer;
    transition: all .3s ease-in;

    i {
      color: var(--panel-text);
      margin-right: 10px;
      font-size: 1rem;
    }

    span {
      color: var(--panel-text);
      font-size: .9rem;
      font-weight: 600;
      padding: 0;
      text-transform: initial;
    }
  }
}

// Tooltips
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  //border-bottom: 1px dotted hsl(283, 39%, 53%);

  &:hover {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
    .tooltiptext-below {
      visibility: visible;
      opacity: 1;
    }
    .tooltiptext-above {
      visibility: visible;
      opacity: 1;
    }
  }

  .tooltiptext {
    visibility: hidden;
    width: 100%;
    min-width: 300px;
    max-width: 350px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 7px 5px 7px 5px;
    border-radius: 6px;
    font-size: .9rem;
    letter-spacing: -0.05px;
    line-height: 1.65;
    word-break: break-word;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1000;
    bottom: -56px;
    left: -216px;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

  }

  .tooltiptext-below {
    visibility: hidden;
    width: 100%;
    min-width: 300px;
    max-width: 350px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 7px 5px 7px 5px;
    border-radius: 6px;
    font-size: .9rem;
    letter-spacing: -0.05px;
    line-height: 1.65;
    word-break: break-word;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1000;
    bottom: -64px;
    left: 4rem;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

  }

  .tooltiptext-above {
    visibility: hidden;
    width: 100%;
    min-width: 300px;
    max-width: 350px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 7px 5px 7px 5px;
    border-radius: 6px;
    font-size: .9rem;
    letter-spacing: -0.05px;
    line-height: 1.65;
    word-break: break-word;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1000;
    bottom: 43px;
    left: 0;
    margin-left: 0;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

  }
}

// Modals
.modal {
  z-index: 9999;
}

.modal-content {

  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  padding: 30px;
  border-radius: 5px;
  background: var(--background) !important;
  z-index: 9999;

  h3 {
    font-family: sans-serif;
    font-size: 1.65rem;
    color: var(--panel-text);
    margin: 0;
    font-weight: bold;
  }

  b {
    font: 700 1.3rem "Roboto", sans-serif;
    color: #EC3636;
  }

  p, .checkbox {
    font-family: sans-serif;
    font-size: 1.1rem;
    color: var(--panel-text);
    margin: 0;
    line-height: 1.65;
  }
  p.note {
    font-family: sans-serif;
    font-size: .95rem;
    color: var(--panel-text);
    margin: 0;
    line-height: 1.65;
    font-style: italic;
    font-weight: bold;
  }

  .search {
    width: 90%;
    margin-top: 10px;

    .field.has-addons {
      width: 100%;
      display: flex;
      flex-direction: column;

      .control:first-child {
        width: 100%;
      }
      .input  {
        background-color: var(--input-bg);
        border-color: transparent;
        border-radius: 4px;
        color: var(--outbound-body);
        box-shadow: var(--input-box);

        &::placeholder {
          color: var(--outbound-body);
        }
      }
    }

    form {
      margin-top: 15px;

      .help.is-danger {
        color: rgb(241 70 104) !important;
        font-size: .9rem !important;
        margin: 10px 0 0 4px !important;
      }
    }
  }

  .button.is-primary {
    margin-top: 14px;
    background-color: var(--outbound-button-bg);
    color: var(--outbound-button-text);
  }

  .loading-section {
    width: 100%;
    height: 100%;

    padding: 30px;

    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;

    .loader, .select.is-loading::after, .control.is-loading::after {
      -webkit-animation: spinAround 500ms infinite linear;
      animation: spinAround 500ms infinite linear;
      border: 5px solid #FF56A1;
      border-radius: 290486px;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 3em;
      position: relative;
      width: 3em;
    }

    p {
      padding-top: 12px;
      font-weight: bold;
    }
  }
}
